<template>
  <div class="home">
    <img alt="logo" src="../assets/logo.png">
    <RecordForm :taxRegimes="this.taxRegimes" :billerTypes="this.billerTypes" :company='this.company' :recordProp='this.record' />
  </div>
</template>

<script>
import RecordForm from '../components/company/RecordForm.vue'

export default {
  name: 'RecordNew',
  components: {
      RecordForm
  },
  data: () => ({
    taxRegimes: [],
    billerTypes: [],
    company: {},
    record: {}
  }),
  methods: {
    async getTaxRegimes () {
      this.$axios
      .get('main/getTaxRegimes')
      .then(response => {
        this.taxRegimes = response.data.taxRegimes
      })
      .catch(error => console.log(error))
    },
    async getBillerTypes () {
      this.$axios
      .get('main/getBillerTypes')
      .then(response => {
        this.billerTypes = response.data.billerTypes
      })
      .catch(error => console.log(error))
    },
		async getCompany () {
      this.$axios
      .get('company/getCompany', { params: { company: this.company } })
      .then(response => {
        this.company = response.data.company
        // console.log(response.data.record)
        this.record = response.data.record
      })
      .catch(error => console.log(error))
    }
  },
  mounted(){
		this.company.id = this.$route.params.company_id
    this.getCompany()
    this.getTaxRegimes()
    this.getBillerTypes()
		

  }
}
</script>